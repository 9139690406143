 @media (min-width: 992px) {
.small-sidebar{

    display: none;

}   
 }

 .small-sidebar  .sidebar-container {
     position: fixed;
     inset: 0;
     background: rgba(0, 0, 0, 0.7);
     display: flex;
     justify-content: center;
     align-items: center;
     z-index: -1;
     opacity: 0;
     transition: 0.3s ease-in-out all;
 }

 .small-sidebar  .show-sidebar {
     z-index: 999;
     opacity: 1;
 }
.small-sidebar  .logo {
    width:200px;
 } 
 .small-sidebar .content {
     background: #fff;
     width: 90vw;
     height: 95vh;
     border-radius: 0.25rem;
     padding: 4rem 2rem;
     position: relative;
     display: flex;
     align-items: center;
     flex-direction: column;
 }

 .small-sidebar .close-btn {
     position: absolute;
     top: 10px;
     left: 10px;
     background: transparent;
     border-color: transparent;
     font-size: 2rem;
     color:#842029;
     cursor: pointer;
 }

.small-sidebar .nav-links {
     padding-top: 2rem;
     display: flex;
     flex-direction: column;
 }

 .small-sidebar .nav-link {
     display: flex;
     align-items: center;
     color: #627d98;
     padding: 1rem 0;
     text-decoration: none;
     text-transform: capitalize;
     transition: 0.3s ease-in-out all;
 }

 .small-sidebar .nav-link:hover {
     color: #ff0080;
 }

 .small-sidebar .nav-link:hover .icon {
     color: #ff0080;
 }

 .small-sidebar .icon {
     font-size: 1.5rem;
     margin-right: 1rem;
     display: grid;
     place-items: center;
     transition: 0.3s ease-in-out all;
 }

 .small-sidebar .active {
     color: #9E0FEC;
 }

 .small-sidebar .active .icon {
     color: #9E0FEC;
 }