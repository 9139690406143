.error-page{
    
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-page img {
    max-width: 600px;
    display: block;
    margin-bottom: 2rem;
}


.error-page h3 {
    margin-bottom: 0.5rem;
}

.error-page p {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.error-page a {
    color: #FF00FF;
    text-decoration: underline;
    text-transform: capitalize;
}