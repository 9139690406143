.page-btn-container{
    height: 6rem;
     margin-top: 2rem;
     display: flex;
     align-items: center;
     justify-content: flex-end;
     flex-wrap: wrap;
     gap: 1rem;
} 

 .page-btn-container .btn-container {
     background: pink;
     border-radius: 0.25rem;
 }

 .page-btn-container .pageBtn {
     background: transparent;
     border-color: transparent;
     width: 50px;
     height: 40px;
     font-weight: 700;
     font-size: 1.25rem;
     color: #ff4500;
     transition: 0.3s ease-in-out all;
     border-radius: 0.25rem;
     cursor: pointer;
 }

 .page-btn-container .active {
     background: #ff4500;
     color: #fff;
 }

 .page-btn-container .prev-btn,
 .page-btn-container .next-btn {
     width: 100px;
     height: 40px;
     background: #fff;
     border-color: transparent;
     border-radius: 0.5rem;
     color:#ff4500 ;
     text-transform: capitalize;
     letter-spacing: 1px;
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 0.5rem;
     cursor: pointer;
     transition: 0.3s ease-in-out all;
 }

 .page-btn-container .prev-btn:hover,
 .page-btn-container .next-btn:hover {
     background: #ff4500;
     color: #fff;
 }