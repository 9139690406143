.predictions-container {

    margin-top: 4rem;
}

.predictions-container  h2 {
      text-transform: none;
  }

  .predictions-container .tp {
      font-weight: 700;
      background-color: #ff0080;
      width: -moz-fit-content;
      width:fit-content;
  }

  .predictions {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 2rem;
  }

  @media (min-width: 992px) {
      .predictions {
          display: grid;
          grid-template-columns: 1fr 1fr;
align-items: flex-start;
          gap: 1rem;
      }
  }