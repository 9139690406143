.stats-container{
margin: 10px;
    display: grid;
    row-gap: 2rem;
    
}  

  @media (min-width: 768px) {
     .stats-container{

         grid-template-columns: 1fr 1fr;
         column-gap: 1rem;
     }
  }

  @media (min-width: 1120px) {
    .stats-container {
    grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1rem;}
  }