.prediction-shop .date-range-selector{
            display: flex;
           /*  align-items: center; */
            justify-content: center;
            max-width: 100%;
        /*    border-radius: 10px;
        overflow: hidden; */
            font-weight: 500;
            font-size: 1.3rem;
} 
.prediction-shop .home-container {
    max-width: 100vw;
    margin: 0;
    padding: 0;
}

.prediction-shop .button_2 {
    background-color: transparent;
}

.prediction-shop .odds-container {
    max-width: 100%;
}

.prediction-shop .home-content {
    max-width: 100%;
   

}

.prediction-shop .left-image,
.prediction-shop .right-image {
    flex: 0 0 30%;
    max-width: 23%;
    height: auto;
    flex-direction: column;
    align-self: flex-end;
}
.prediction-shop .odds-c-i {

        display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            max-width: 100%;
            width: 90%;
        
}
.prediction-shop .odds-component {
    flex: 0 0 40%;
    /*  max-width: 33.33%; */
    height: auto;
align-self: flex-start;

    width: 100%;
}
.prediction-shop .rii {
    display:none;
}

@media (max-width: 445px) {

.prediction-shop  .left-image,
.prediction-shop   .right-image {
        display: none;
    }
}