.verif-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.verif-container .green_btn {
    border: none;
    outline: none;
    padding: 12px 0;
    /* background-color: #3bb19b; */
    background-color: #FA2A55;
    
    border-radius: 20px;
    width: 180px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}