.odds-buttons{
    background-color: transparent;
    border: none;
cursor: -webkit-grab; 
cursor: grab; 
padding: 0.375rem 0.5rem;

}
.odds-buttons:hover{
    border:2px solid black;

}
.odds-buttons.grabbing{
cursor: -webkit-grabbing;
 cursor: grabbing;
background-color: white;
}
.odds-buttons.circle {
    transition: all 1s ease;
}

.odds-buttons.circle:hover {
   
    background: #ff7a59;
    padding: 10px;
    border-radius: 15px;
    box-sizing: border-box;
    transition: all 1s ease;
}
.odds-buttons.triangle {
    /* Default state */
-webkit-transform: scale(1);
    transform:  scale(1);
    transition: all 1s ease;
}
.odds-buttons.triangle:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: all 1s ease;
}

#price-display:before{
    font-family: FontAwesome;
        content: "\f07a";
        position: relative;
        left: -3px;
        transition: all 300ms ease;
}
@-webkit-keyframes bounce {
    0%,
        20%,
        60%,
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    
        40% {
            -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
        }
    
        80% {
            -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
        }
}
@keyframes bounce {

    0%,
    20%,
    60%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    80% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

.star:hover {
    -webkit-animation: bounce 1s 2 both;
    animation: bounce 1s 2 both;
}

@-webkit-keyframes shake-horizontal {

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }

    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }

    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}

@keyframes shake-horizontal {

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }

    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }

    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}


@-webkit-keyframes shake-lr {

    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    10% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }

    20%,
    40%,
    60% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    30%,
    50%,
    70% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }

    80% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
    }

    90% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
}

@keyframes shake-lr {

    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    10% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }

    20%,
    40%,
    60% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    30%,
    50%,
    70% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }

    80% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
    }

    90% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
}

.rectangle:hover {
    transform: skew(10deg);
        transition: all 1s ease-in-out;
    -webkit-animation: shake-lr 2s cubic-bezier(0.455, 0.030, 0.515, 0.955);
    animation: shake-lr 2s cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

.square {
 transition: all 1s ease;
}
.square:hover {
    background: #9E0FEC;
    -webkit-animation: shake-horizontal 2s;
    animation: shake-horizontal 2s;
}