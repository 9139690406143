.search{
    margin: 10px;

}
.search .form {
     width: 100%;
     max-width: 100%;
background-color: white;
padding: 10px;
    }
.search .form:hover {
  box-shadow:0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.form-label {
  display: block;
  font-size:  0.875rem;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}
 .search .form-inputs,
 .search .form-select,
 .search .btn-block {
    width:100%;
     height: 35px;
border: 1px solid #9E0FEC;
border-radius: 0.25rem;
 }

 .search .form-row {
     margin-bottom: 0;
 }
.search .form-textarea {
    height: 7rem;
}
.search .form-center {
     display: grid;
     grid-template-columns: 1fr;
     column-gap: 2rem;
     row-gap: 0.5rem;
 }

.search h5 {
     font-weight: 700;
 }

.search .btn-block {
     align-self: end;
     margin-top: 1rem;
 }

 @media (min-width: 768px) {
    .search .form-center {
         grid-template-columns: 1fr 1fr;
     }
 }

 @media (min-width: 992px) {
.search  .form-center {
         grid-template-columns: 1fr 1fr 1fr;
     }

  .search  .btn-block {
         margin-top: 0;
        
     }
 }