.prediction { background: #fff;
 border-radius: 0.25rem;
 display: grid;
 grid-template-rows: 1fr auto;

 box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);;
margin:25px;
}
.prediction header {
     padding: 1rem 1.5rem;
     border-bottom: 1px solid #000;
     display: grid;
     grid-template-columns: auto 1fr;
     align-items: center;

 }
 .prediction footer {
     padding: 1rem 1.5rem;
     border-top: 1px solid #000;
     display: flex;
     align-items: center;
justify-content: center;
 }
.prediction h5 {
    letter-spacing: 0;
}
.prediction .main-icon {
     width: 60px;
     height: 60px;
     display: grid;
     place-items: center;
     background: #660099;
     border-radius: 0.25rem;
     font-size: 1.5rem;
     font-weight: 700;
     text-transform: uppercase;
     color: #fff;
     margin-right: 2rem;
 }
.prediction .event{
    background-color: pink;
    color:black;
    border-radius: 15px;
    padding: 5px;
    margin :5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.prediction .outcome-desc{
   
    display: flex;
    align-items: space-between;
    justify-content: space-around;
    
}
.prediction .event .home-away{
    display: inline;
}
.prediction .event .home, .prediction .event .away{
background-color: #ff0080;
border-radius: 5px;

}
.prediction .prediction-type {

        margin-right: 5px;
        /* Adjust the value as needed */
}
.prediction .outcome {
background-color: purple;
color:white;
}
.prediction .total-odd {
    background-color: #ff00ff;
    border-radius: 15px;
        text-transform: capitalize;
        letter-spacing: 1px;
        width: auto;
        height: 40px;
        color: black;
        display: flex;
            align-items: center;
           
            justify-content: center;
}
.prediction .outcomeOdd {
    background-color: #ff00ff;
    color: black;
}
.prediction .event h3 {
    color: white;
}
.prediction .info h5 {
     
         margin-bottom: 0.25rem;
 }
.prediction .info p {
    margin: 0;
    text-transform: capitalize;
    color: #829ab1;
    letter-spacing: 1px;
}
 .prediction .status.pending {
     background: #fcefc7;
     color:#e9b949 ;

     margin-left: 5px;
 }

.prediction .status.successful {
color: #0f5132;
    background: #a3e5c6;
    margin-left: 5px;
 }

 .prediction .status.refunded {
     color:#4A0404 ;
     background: #d66a6a;
    margin-left: 5px;
 }
 .prediction .status.exceeded {
     color: #4A0404;
     background: pink;
     margin-left: 5px;
 }
 .prediction .content {
     padding: 1rem 1.5rem;
 }

.prediction .content-center {
     display: grid;
     grid-template-columns: 1fr;
     row-gap: 0.5rem;
   
 }
.prediction .price {
    /* background-color: #ff00ff; */
    border-radius: 5px;
    text-transform: capitalize;
    letter-spacing: 1px;
    width: auto;
    height: 40px;
    /* color: black; */
    display: flex;
    align-items: center;

    justify-content: center;
}
.prediction .price.pending{
    background: #e9c46e;
        color: 
        #a5780e;
}
.prediction .price.successful {
    color: #0f5132;
    background: #87e1b7;

}

.prediction .price.refunded {
    color: #4A0404;
    background: #d97272;

}

.prediction .price.exceeded {
    color: #4A0404;
    background: pink;

}
    @media (min-width: 576px) {
        .prediction .content-center{   grid-template-columns: 1fr 1fr;
        }
    }    
        @media (min-width: 992px) {
        .prediction .content-center{   grid-template-columns: 1fr;
        }
    }
    
        @media (min-width: 1120px) {
    .prediction .content-center{       grid-template-columns: 1fr 1fr;
        }
    }

.prediction .status {
     border-radius: 0.25rem;
     text-transform: capitalize;
     letter-spacing: 1px;
     text-align: center;
     width: 120px;
     height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    
 }

.status-footer {
     margin-top: 1rem;
    display: flex;
    flex-direction: column;
        align-items: center;
 }

 .prediction .edit-btn,
 .prediction .delete-btn {
     letter-spacing: 1px;
     cursor: pointer;
     height: 30px;
 }

 .prediction .edit-btn {
     color: #0f5132;
     background:#d1e7dd;
     margin-right: 0.5rem;
     border: 2px solid #0f5132;
    transition: 0.3s ease;
 }

 .prediction .delete-btn {
      color: #842029;
     background: #f8d7da;
    border: 2px solid #842029;
    transition: 0.3s ease;
 }
 .prediction .edit-btn:hover {
     border-width: 4px;
transition: 0.3s ease-in-out;
    }

 .prediction .delete-btn:hover {
     border-width: 4px;
    transition: 0.3s ease;
 }

 .prediction .actions:hover {
     visibility: visible;
        
    } 