.range-button{
    flex: 1;
    height: 50px;
    min-width: 100px;
    background-color: transparent;
    border: none;
        text-decoration: none;
        border-radius: 15px;
    margin-left: 5px;
    font-weight: 600;
                font-size: 0.9rem;
                white-space: nowrap;
                cursor:pointer;
}
.range-selector-wrapper{
    display: flex;
    align-items: center;
    background-color: #a40aa3;
        justify-content: space-between;
        padding: 5px 0px 0px 0px;
       max-width: 100%; 
border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    }
.range__h3{
    color:#ff00ff;
    white-space: nowrap;
}
.range-button:hover {

    background-color: #ff0080;
}
.range-buttons{
    background-color: #a40aa3;
 
    color:#ff00ff;
    overflow-x: scroll;
    display: flex;
        align-items: center;
    border-bottom-right-radius: 15px;
    }
.selected-range-button{
    background-color: #ff0080;
}
.range-buttons::-webkit-scrollbar {
    background-color: #ffc0cb transparent;
    color: #ff0080;
    border-radius: 10px;
}

.range-buttons::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ff0080;
}

.range-buttons::-webkit-scrollbar-thumb:hover {
    background-color: #ff0080;
}

.range-buttons {
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #ff0080 transparent;
    /* For Firefox */
}

.range-buttons::-ms-track {
    background-color: transparent;
    /* For Edge (Legacy) */
}

/* Optional: Customize scrollbar track */
.range-buttons::-webkit-scrollbar-track {
    background-color: transparent;
    background: #ffc0cb;
    border-radius: 10px;
    /* For Chrome, Safari, and Opera */
}

.range-buttons::-moz-scrollbar-track {
    background-color: transparent;
    /* For Firefox */
}
@media screen and (max-width:445px) {
    .range__h3 {
        display: none;
    }
.range-selector-wrapper {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.range-buuttons {
    border-bottom-right-radius: 0px;
}
}