.help {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin: 20px;
background-color: white;
}
.help-content{
    margin: 0 100px;
}
.help h2 {
    color:#ff0045;
}

.faq h4 {
 color: black;

}
.faq{
    margin: 20px 100px;
}

@media screen and (max-width: 768px) {
.help {
        margin: 0;
       
    }
        .help-content {
            margin: 0 30px;
        }
        .faq {
            padding: 10px;
            margin: 5px;

        }
}
@media screen and (max-width: 445px) {
    .help {
        text-align: justify;

    }
  
}