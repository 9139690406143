.charts-container {

    margin-top: 4rem;
    text-align: center;
} 
    
.charts-container  button {
      background: transparent;
      border-color: transparent;
      text-transform: capitalize;
      color: #660099;
      font-size: 1.25rem;
      cursor: pointer;
  }

 .charts-container h4 {
      text-align: center;
      margin-bottom: 0.75rem;
  }
  .charts-container .chart-btn {
      color: #660099;
      background-color: #ff0080;
      border-radius: 15px;
  }