.stat-item {
    padding: 2rem;
    background: #fff;
    border-radius: 0.5rem;
    border-bottom: 5px solid var(--color);
}

.stat-item header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stat-item .count {
    display: block;
    font-weight: 700;
    font-size: 50px;
    color: var(--color);
}

.stat-item .title {
    margin: 0;
    text-transform: capitalize;
    font-size: 1.3rem;
    letter-spacing: 1px;
    text-align: left;
    margin-top: 0.5rem;
}

.stat-item .icon {
    width: 70px;
    height: 60px;
    background: var(--background-color);
    border-radius: var(--borderRadius);
    display: flex;
    align-items: center;
    justify-content: center;
}

.stat-item .icon svg {
    font-size: 2rem;
    color: var(--color);
}