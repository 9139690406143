.form-input{
    display: flex;
    flex-direction: column;
    /* width: 280px; */
    width: auto;
}
.form-input input{
    padding: 13px;
    margin: 5px 0px;
    border-radius: 10px;
    border: 1px solid gray
}
.form-input input:read-only {
    /* Styles for read-only input fields */
    background-color: lightgray;
}
.form-input label {
    font-size: 16px;
    color: black;
}
.form-input span {
    background-color: white;
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

.form-input input:invalid[focused="true"]{
border: 2px solid red;
}
.form-input input:invalid[focused="true"] ~ span{
    display: block;
}
