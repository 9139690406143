.Navbar {
  display: flex;
  align-items: center;
  font-size: 24px;
  padding: 0px 20px;
}

.Navbar .logo {
  width: 200px;
}

a {
  display: block;

  text-align: center;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

#navbar li {
  list-style: none;
  padding: 0px 2px;
  position: relative;
}

#navbar li a {
  text-decoration: none;
  font-family: "Roboto", serif;

  font-weight: 800;
  font-size: 1.5rem;
  padding: 0 5px;
  color:
        /*#f9e1e1*/ #050505;
  transition: 0.3s ease-in-out;
}

#navbar li a:hover,
#navbar li a.active {
  background-color: #ff0080;
  border-radius: 5px;
  margin-top: 5px;
}

#navbar li a:hover::after,
#navbar li a.active::after {
  background: #ff0080;
  position: absolute;
}
#for-sale a:hover,
#for-sale a.active {
  background-color: transparent !important;
  border-radius: 5px;
  margin-top: 12px !important;
}

#for-sale a:hover::after,
#for-sale a.active::after {
  background: transparent !important;
  position: absolute;
}

.navbar-sign {
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.navbar-sign__button {
  padding: 10px 20px;
  margin-bottom: 10px;
  white-space: nowrap;
  border: none;
  font-size: 16px;
  display: inline-block;
}

.navbar-sign__button--log-in {
  background-color: #a40aa3;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  color: pink;
  text-decoration: none;
}
.navbar-sign__button--log-in:hover {
  color: pink;
  text-decoration: none;
}

.navbar-sign__button--sign-up {
  background-color: #ff4500;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  color: black;
  text-decoration: none;
}
.navbar-sign__button--sign-up:hover {
  color: black;
  text-decoration: none;
}

.navbar__menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}
.navbar__menu svg {
  cursor: pointer;
}
.navbar__menu-container {
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  background: var(--color-bg);
  padding: 1rem 0rem;
  position: absolute;
  top: 12px;
  left: 0;
  margin-top: 1rem;
  min-width: 150px;
  border-radius: 5px;
}
.navbar__menu-links-sign {
  display: none;
}
.navbar__menu-links-sign {
  padding: 10px 20px;
  margin-bottom: 10px;
  white-space: nowrap;
  border: none;
  font-size: 16px;
  /*  display: inline-block; */
}

.menu-links-sign-in__button {
  background-color: #a40aa3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: pink;
  text-decoration: none;
}
.menu-links-sign-in__button:hover {
  color: black;
  text-decoration: none;
}
.menu-links-sign-up__button {
  background-color: #ff4500;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: black;
  text-decoration: none;
}
.menu-links-sign-up__button:hover {
  color: black;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .Navbar {
    padding: 2rem 4rem;
  }
  .navbar__container {
    display: none;
  }
  .navbar__menu {
    display: flex;
  }
  #navbar {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .navbar__menu-links-sign {
    display: none;
  }
}

@media screen and (max-width: 445px) {
  .Navbar {
    padding: 2rem;
  }
  .navbar__container {
    display: none;
  }

  .navbar__menu {
    display: flex;
  }

  #navbar {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .navbar__menu-container {
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: center;
    background: var(--color-bg);
    padding: 1rem 0rem;
    top: 12px;
    left: 0;
    margin-top: 1rem;
    min-width: 150px;
    border-radius: 5px;
    position: absolute;
    left: -40px;
  }
  .navbar__menu-links-sign {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navbar-sign {
    display: none;
  }
}
