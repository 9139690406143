.dnavbar{
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.1);
    background: #fff;
z-index: 999;
max-width: 100vw;
}
 .dnavbar  .logo {
     display: flex;
     align-items: center;
    width: 200px;
 }

 .dnavbar   .nav-center {
     display: flex;
     /* width: 90vw; */
    width: 90%;
     align-items: center;
     justify-content: space-between;
 }
 .dnavbar .clock {
    font-size: 1.2rem;
    background-color: #ff00ff;
    color:black;
    border-radius: 5px;
    margin-right: 5px;
 }
 .dnavbar .odds-format__select select {
    font-size: 1.2rem;
     background: #ff00ff;
     color: black;
     border-radius: 5px;
 }


  .dnavbar .odds-format__select select option {
      background-color: #ff00ff !important;
      color: black !important;
  }

 .dnavbar .currency__select select {
    font-size: 1.2rem;
     background: #ff00ff;
     color: black;
     border-radius: 5px;
 }
 .dnavbar .currency__select select option {
     background-color: #ff00ff !important;
     color: black !important;
 }
  .dnavbar .toggle-btn {
     background: transparent;
     border-color: transparent;
     font-size: 1.75rem;
     color: #ff00ff;
     cursor: pointer;
     display: flex;
     align-items: center;
 }


 .dnavbar .btn-container {
     position: relative;
 }

 .dnavbar  .btn {
  padding: 0.375rem 0.75rem;

     display: flex;
     align-items: center;
     justify-content: center;
     gap: 0 0.5rem;
     position: relative;
     box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
        0 2px 4px -1px rgba(0, 0, 0, 0.6);
background-color: #ff00ff;
transition: background-color 2s ease;
white-space: nowrap;
border: none;
    }

 .dnavbar .btn.clicked{
    background-color: #a40aa3;
transition: background-color 2s ease;
 }
 .dnavbar  .dropdown {
     position: absolute;
     top: 40px;
     left: 0;
     width: 100%;
     background: #ff00ff;
     box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
     padding: 0.5rem;
     text-align: center;
     visibility: hidden;
     border-radius: 0.25rem;
     
 }

 .dnavbar  .show-dropdown {
     visibility: visible;
 }

.dnavbar .dropdown-btn {
    width: 100%;
     background: transparent;
     border-color: transparent;
     color:#000;
     letter-spacing: 1px;
     text-transform: capitalize;
     cursor: pointer;
   
 }

 .dnavbar  .logo-text {
     display: none;
     margin: 0;
     color: #ff00ff;
 }
   .dnavbar .logo {
       display: none;
   }
 @media screen and (min-width: 992px) {
  .dnavbar {
      
      position: sticky;
        top: 0;
  } 
/* .dnavbar .toggle-btn {
    display:none;} */
        .dnavbar .nav-center {
         width: 90%;
     }

.dnavbar .logo {
    display: inline;
}
.dnavbar .clock-format {
    display: flex;
    align-items: flex-start;
   
}
 .dnavbar  .logo-text {
         display: block;
     }
 }

 @media screen and (max-width: 400px) {
.dnavbar .odds-format__select select {
        font-size: 1rem;
     
    }



    .dnavbar .currency__select select {
        font-size: 1rem;
     }
.dnavbar .clock {
    font-size: 1rem;
text-align: center;}
 }