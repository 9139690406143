.odds__section {
    background-color: #a40aa3;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.6);
margin: 0 auto;
padding: 0;

}
.container-image-odds {
display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*  max-width: 33.33%; */
    height: auto;

    width: 100%;
}
.c-image{
    margin-top: 20px;
    max-width:350px;
    height: auto;
}
.price-total{
    margin: 20px;
   font-size: 1.15rem;
}
.odds-format__radio {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    white-space: nowrap;
}

.odds-format__radio input {
    margin: 0px 10px 0px 50px;
}

.odds-format__radio input[type='radio'] {
    accent-color: #FFD700;
}

.odds-buttons__context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.odds__list {
    list-style: none;
    display: flex;
margin: 0;
padding: 0;
}
.odds__list li {
    margin: 0 10px;
    box-sizing: border-box;
}
/* .odds__list li:first-child {
    margin-left: 0;
    padding-left: 0;
}
 */
@media screen and (max-width:1045px) {
    .odds-format__radio {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
    }
.odds-format__radio input {
    margin: 0px 10px 0px 80px;
}
    .odds-buttons__context {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 0;
        padding: 0 0;
    }

    .odds__list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        flex-direction: row;
        margin: 0 0;
        padding: 0 0;
    }

    .odds__list li {
        width: 26%;
        margin: 0;
        order: 1;
        box-sizing: border-box;
    }

    /* Place the first 3 items on the first row */
    .odds__list li:nth-child(-n+3) {
        order: 1;
    }

    /* Place the last 2 items on the second row */
    .odds__list li:nth-child(n+4) {
        order: 2;
    }
}
@media screen and (max-width:768px) {
    .odds-format__radio {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
margin: auto;
        }
                .odds-format__radio input {
                    margin: 0px 10px 0px 50px;
                }
    .odds-buttons__context {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 0;
        padding: 0 0;
    }

    .odds__list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        flex-direction: row;
        margin: 0 0;
        padding: 0 0;
    }

    .odds__list li {
        width: 30%;
        margin: 0 ;
        order: 1;
        box-sizing: border-box;
    }

    /* Place the first 3 items on the first row */
    .odds__list li:nth-child(-n+3) {
        order: 1;
    }

    /* Place the last 2 items on the second row */
    .odds__list li:nth-child(n+4) {
        order: 2;
    }
}

@media screen and (max-width:445px) {
.odds-buttons__context {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .odds__list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        flex-direction: row;
        margin: 0;
        padding: 0;
    }

    .odds__list li {
         width: 27%; 
        margin: 0;
        order: 1;
    }

    /* Place the first 3 items on the first row */
    .odds__list li:nth-child(-n+3) {
        order: 1;
    }

    /* Place the last 2 items on the second row */
    .odds__list li:nth-child(n+4) {
        order: 2;
    }
  
}
