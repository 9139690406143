@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loading {
    width: 6rem;
    height: 6rem;
    border: 5px solid #829ab1;
    border-radius: 50%;
    border-top-color: #ff0080;
    animation: spinner 2s linear infinite;
}

.loading-center {
    margin: 0 auto;
}