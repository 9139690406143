.predictions-container {
    display: flex;
    flex-direction: column;
    /* min-height: 100%; */
    margin: 0;
        padding: 0;
}

#pricing {
    background-color: #a40aa3;
    text-align: justify;
    /* height: 100%; */
    /* font-weight: 400; */
    font-size: 18px;
    padding: 2rem;
    border-radius: 0.5rem;
    margin: 2rem;
    color: white;
    box-shadow: 0 0 1rem rgba(0, 0, 0, .5);
    overflow: hidden;
}

#pricing h1,
h2,
h3 {
    color: #ff4500;
}
.price-table {
    float: left;
    margin-right: 20px;
    position: relative;
}
.price-table th {
    border: 1px solid #ddd;
 font-weight: bold;
 padding: 8px;
 text-align: center;   
}

.price-table td {
     border: 1px solid #ddd;
     padding: 8px;
     text-align: center;
 }
.odds__radio {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.odds__radio input {
    margin: 0px 10px 0px 20px;
}

.odds__radio input[type='radio'] {
    accent-color: #FFD700;
}
.pricing-grid-container{
    display: grid;
        grid-template-columns: 1fr 1fr;
      
        gap: 20px;
}
.pi-container {
     flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
}
.pi-container img {
    max-width: 100%;
    align-self: center;
}
.pi-container p {
    text-align: center;
}
@media screen and (max-width:768px) {

.odds__radio input {
        margin: 0px 5px 0px 5px;
    }
   .pricing-grid-container {
       grid-template-columns: 1fr;
        gap: 0px;
   }

   .pricing-content-container {
       text-align: center;
   }


   .pi-container p {
       padding: 0px;
       text-align: center;
   }
   #pricing {
    
       padding: 0px;
    
     }
         .price-table th {
             padding: 0px
         }
    
         .price-table td {
             padding: 0px;
}
.price-table {
 align-self: center;
 margin: 0px;
}
}