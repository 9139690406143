
.sharedLayout .dashboard {
      display: grid;
      grid-template-columns:auto 1fr;
        grid-template-rows: auto 1fr auto ;
  }

  .sharedLayout .dashboard-page {  
    max-width: 100vw;
      margin: 0 ;
      padding:  0;
   /*  flex-grow: 1; */
  }
  .sharedLayout .dashboard-grid-item {
/*    display:grid; important for displaying full page length aside*/
max-width: 100%;
margin: 0;
padding: 0;
  }
 .sharedLayout .dashboard-footer {
     grid-column: 1 / span 2;
     /* Spans two columns */
     max-width: 100%;
 }

  @media (min-width: 992px) {
 

          .sharedLayout .dashboard-page {
          width: 100%;
      } 
  
    }