.big-sidebar{

    display: none;
} 

  @media screen and (min-width: 992px) {
.big-sidebar {
    display: block;
      box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1);
}
.big-sidebar      .sidebar-container {
          background: #fff;
          min-height: 100vh;
          height: 100%;
          width: 250px;
          margin-left: -250px;
          transition: 0.3s ease-in-out all;
      }

  .big-sidebar    .content {
          position: sticky;
          top: 0;
      }

 .big-sidebar     .show-sidebar {
          margin-left: 0;
      }

  .big-sidebar    header {
          height: 6rem;
          display: flex;
          align-items: center;
          padding-left: 2.5rem;
      }

  .big-sidebar    .nav-links {
          padding-top: 2rem;
          display: flex;
          flex-direction: column;
      }

  .big-sidebar    .nav-link {
          display: flex;
          align-items: center;
          color: #627d98;
          text-decoration: none;
          padding: 1rem 0;
          padding-left: 1.5rem;
          text-transform: capitalize;
          transition: 0.3s ease-in-out all;
          border: none;
      }

        .big-sidebar  .nav-link:hover {
          background: #ffc0cb;
          padding-left: 2rem;
          color: #ff0080;
         
      }

         .big-sidebar .nav-link:hover .icon {
          color: #ff0080;
      }

         .big-sidebar .icon {
          /* font-size: 1.5rem; */
          margin-right: 1rem;
          display: grid;
          place-items: center;
          /* transition: 0.3s ease-in-out all; */
      }

        .big-sidebar  .nav-link.active {
          /* background:#a22be2; */
          /* background: purple; */
          background: #ff0080;
          padding-left: 1.5rem;
          color: #ffc0cb;
      font-weight: 700;
      font-size: 1.5rem;
      border: none;
      
        }

        .big-sidebar  .nav-link.active .icon {
                  color: pink;
        }
    }