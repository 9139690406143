.profile {  border-radius: 0.25rem;
  width: 100%;
  padding: 3rem 2rem 4rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
  h3 {
      margin-top: 0;
  }

  .form {
      margin: 0;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      max-width: 100%;
      width: 100%;
  }

.form  .form-input {
      margin-bottom: 0;
      width: 100%;
  }

 .form .form-center {
      display: grid;
      row-gap: 0.5rem;
  }

 .form .form-center button {
      align-self: end;
      height: 35px;
      margin-top: 1rem;
  }

 /*  .form .btn-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
      align-self: flex-end;
      margin-top: 0.5rem;

  } */
  .form .title input[type='radio'] {
      accent-color: #ff0080;
  }
 .form .btn-container button {
    height: 35px;
}
 .form .btn.btn-block {
      background:#ff0080;
  }

 .form .btn.btn-block:hover {
   background-color:  #222;
   color: #ff00ff;
  }
  .confirm.btn{
background: #ff0080;
max-width: 100%;
  margin-left: 0;
    height: 35px;
    margin: 2rem;
  }
  .confirmation{
    color:black;
  }
  .delete.btn{
background: #ff0000;
  }
  .cancel.btn{
background: #ff0080;
margin-left: 1rem;
  }
  .confirm.btn:hover {
    background-color: #222;
        color: #ff00ff;
  }
  .delete.btn:hover{
background-color: #222;
    color: #ff00ff;
  }
  .cancel.btn:hover{
background-color: #222;
    color: #ff00ff;
  }
.profile-img img{
    margin-top: 30px;
    width: 300px;
}
.form .title-label {
    margin:0 auto;
}

.form .title label:not(:last-child) {
    margin-right: 55px;
}
  @media (min-width: 768px) {
      .form .form-center {
          grid-template-columns: 1fr 1fr;
          align-items: center;
          column-gap: 1rem;
      }

     .form .btn-container {
          margin-top: 0;
      }
  }

  @media (min-width: 1120px) {
   .form   .form-center {
          grid-template-columns: 1fr 1fr 1fr;
      }

   .form   .form-center button {
          margin-top: 0;
      }
  }