.terms {
    align-self:center;
    background-color: #F7F8FA;
    padding:50px;
    margin: 100px;
    text-align: justify;
}
.terms section {
    background-color: #F1F3F7;
    padding: 10px ;
    margin:  10px ;
}
.terms ul {
   
    padding: 0px;
    margin: 0px;

}
.terms ul li {
    background-color: #F1F3F7;
    padding: 10px  ;
    margin:  10px ;
    
}

.terms ul  {
list-style: none;
}
#t00 {
    display: flex;
    ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#t01 nav ul {
    display: flex;
        
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

}
#t01 nav ul li {
    margin:0px}
.anchor {
    display: inline;
}
@media screen and (max-width: 768px) {
.terms {
        align-self: center;
        background-color: #F7F8FA;
        padding: 5px;
        margin: 10px;
        text-align: justify;
    }
    .terms ul {
    
            padding: 0;
            margin: 0 10px;
    
        }
        
                 .terms ul li{
                     background-color: #F1F3F7;
                     padding: 0;
                     margin: 0 10px;
        
                 }
                .terms section {
                    background-color: #F1F3F7;
                    padding: 0;
                    margin: 0px;
                }
}