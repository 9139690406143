.footer { 
    display: flex;
    background :var(--color-footer);
    flex-direction: column;
    padding: 4rem 4rem;
    white-space: nowrap;
   /*  width: auto; */
/*  margin-right: 0;
 margin-left: 0; */
   margin-top: auto;
}
.footer nav ul {
    list-style : none;
}
.footer h1{
    color: #9E0FEC;
    font-size: 2.2rem;
    
}
.footer nav li a {
    text-decoration: none;
    font-family: 'Roboto', serif;
    padding: 0 0px;
    color: #9E0FEC;        
    transition: 0.3s ease-in-out;
    display: block;
    text-align: start;
}
.footer li {
    display: flex;
    text-align: justify;
    padding: 2px;
    margin: 0;
}
.footer ul {
    padding: 0;
    margin: 0;
}
.footer-top{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
}
.footer-other-pages ul {
    list-style: none;
    margin: 0px;
    padding:0px;
}
.footer-other-pages li {
    padding-top: 0px;
    /* padding-left: 20px; */
    display: inline-block;

    text-align: start;
}
.footer-other-pages li:nth-child(2) {
    padding-left: 20px;
}
.footer-other-pages li a {
        text-decoration: none;
        font-family: 'Roboto', serif;
        padding: 0 5px;
        color: pink;
        transition: 0.3s ease-in-out;
        display: block;

        text-align: start;
    }
.footer-bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.copyright h1 {
    color: pink;
}

.social{
    display: flex;
    align-items: center;
    gap:45px;
}
.social-icons {
    display: flex;
    align-items: center;
    
    /* justify-content: space-between; */
    gap:25px;
}
@media screen and (max-width: 768px) {
.footer {
        display: flex;
        background: var(--color-footer);
        flex-direction: column;
        padding: 0;
        white-space: nowrap;
        width: 100%;
        margin-top: auto;
    }
    .footer h1{
        font-size: 1.5rem;
    }
          .copyright h1 {
              font-size: 1.8rem;
          }
    .footer-bottom {
        flex-direction: column;
        align-items: center;
            justify-content: center;
    }
        .footer-other-pages{
            margin-top: 20px;
        }
           .footer-other-pages li {
               padding-left: 5px;
           }

         .social, .copyright , .other-pages {
        width: 100%;
             text-align: center;
         }
                 .social{
                        display: flex;
                        align-items: center;
                
                        justify-content: center;
                        gap: 45px;
                    }
                 .social-icons {
                     display: flex;
                     align-items: center;
        
                     justify-content: center;
                     gap:20px;
                 }
}

@media screen and (max-width: 445px) {

.footer {
        display: flex;
        background: var(--color-footer);
        flex-direction: column;
        padding: 0;
        white-space: nowrap;
        width: 100%;
        margin-top: auto;
    }
         .footer h1 {
             font-size: 1.5rem;
         }

    .footer-bottom {
        flex-direction: column;
       
    }

.social,
.copyright,
.other-pages {
    width: 100%;
    text-align: center;
}
 .social {
 
     gap: 15px;
 }
        .social-icons {
            /* display: flex;
            align-items: center; */
    
            /* justify-content: center; */
            gap:15px;
margin-left: 15px;
        }
}