.SportsNavbar{
    display :flex;
    align-items:center;
 background-color: #a40aa3;
 justify-content:space-between ;
 padding: 5px 0px 10px 0px;
height: auto;
/* position: sticky;
 */  
    z-index: -1;

}
.SportsNavbar h1 {
    color:#ff00ff;

}

.sportsIcon {
    width: 50px;
    height: 50px;
  
    opacity: 0.5;
   
}

.sportsNavbarItems {
    display:flex ;
    align-items: center;
    justify-content: space-between;
    box-sizing: content-box;
    height: auto;
overflow-x: scroll;
padding: 5px 0px 10px 0px;
margin: auto;
}
.sportsNavbarItems::-webkit-scrollbar {
    background-color: #ffc0cb transparent;
    color: #ff0080;
    border-radius: 10px;
}
.sportsNavbarItems::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ff0080;
}

.sportsNavbarItems::-webkit-scrollbar-thumb:hover {
    background-color: #ff0080;
}

.sportsNavbarItems {
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #ff0080 transparent;
    /* For Firefox */
}

.sportsNavbarItems::-ms-track {
    background-color: transparent;
    /* For Edge (Legacy) */
}
/* Optional: Customize scrollbar track */
.sportsNavbarItems::-webkit-scrollbar-track {
    background-color: transparent;
    background: #ffc0cb;
    border-radius:10px;
    /* For Chrome, Safari, and Opera */
}

.sportsNavbarItems::-moz-scrollbar-track {
    background-color: transparent;
    /* For Firefox */
}
.sportsNavbarItems li{
    /* display: block; */
/*     align-items: block; */
    list-style:none;
   /* padding: 10px; */
  margin: auto;
     position:relative;
    background-color: purple;
    border-radius: 0px;
}

.sportsNavbarItems li button {
    background-color: transparent;
    border: none;
    text-decoration: none;
    font-family: 'Noto Sans', serif;
    font-weight: 800;
    font-size: 1.5rem;
    color: pink;
    transition: 0.3s ease-in-out;

    display: flex;

    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    padding: 20px;
    /* border-radius: 15px; */
    align-items: center;
    text-align: center;


}

.sportsNavbarItems li button:hover,
.sportsNavbarItems li button.active {
    color: #ff0080;
    background-color: #a40aa3;
    border-radius: 0px;
}

.sportsNavbarItems li button:hover::after,
.sportsNavbarItems li button .active::after {
    content: "";
    width: 80%;
    height: 2px;
    background: #ff0080;
    position: absolute;
    bottom: -4px;
    left: 10%;
}

.sportsNavbarItems li button.active .sportsIcon {

    opacity: 1;
}

@media screen and (max-width:445px) {
    .sports__h1{
        display:none;
    }

}