.log-in {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
   /* overflow: scroll; */
}

.form-container-logIn,
.image-container-logIn {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px;
}
.log-in form {

    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);
    padding: 20px;
    border-radius: 20px;
}

.log-in form h1 {
    color: #660099;
    text-align: center;
}

.log-in form button {
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    background-color: #FF4500;
    color: #660099;
    border-radius: 5px;
    font-weight: bold;
    font-size: 21px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 30px;
}
.log-in form button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
.log-in form p {
    display: inline-block;
    vertical-align: middle;
    color: black;
}

.sign-up__link {
    display: inline-block;
    margin-left: 30px;
    /* adjust as needed */
    vertical-align: middle;
            border-radius: 12px;
            color: #ff4500;
            text-decoration: none;
            cursor: pointer;
}
.sign-up__link:hover {
    color: #ff4500;
    text-decoration: none;
}

.vl-li {
    width: 2px;
    background-color: #ccc;
    height: 400px;
    align-self: center;
}

.log-in-img {
    max-width: 70%;
    align-self: center;

}
.logIn-logo img{
max-width: 47%;   
margin-left: 150px;
}
.log-in-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .image-container-logIn {
        display: none;
    }

    .vl-li {
        display: none;
    }

    .form-container-logIn {
        width: 100%;
        /* align-self: center; */
    }
        .logIn-logo img {
            max-width: 300px;
            align-self: center;
        margin-left: 10px;
          }
               
}