.sign-up{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.sign-up-logo{
    display: flex;
        align-items: center;
        justify-content: center;
      
}
.signUp-logo{
    align-self: center;
}
.signUp-logo img {
    max-width: 47%;
    margin-left: 150px;
}
.sign-up form{

/* ff 3.6+ */
    background: -moz-radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);
    align-self: center;
    padding: 20px;
    border-radius: 20px;
}
.sign-up form h1{
    color: #660099;
    text-align: center;
}
.form-container,
.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
margin: 40px;
}
.sign-up form button{
width: 100%;
height: 50px;
padding: 10px;
border: none;
background-color: #FF4500;
color: #660099;
border-radius: 5px;
font-weight: bold;
font-size: 21px;
cursor: pointer;
margin-top: 5px;
margin-bottom: 30px;
}
.sign-up form button:disabled {
    opacity: 0.7;   
    cursor: not-allowed;
}

.sign-up form p {
    display: inline-block;
    vertical-align: middle;
    color: pink;
}

.log-in__link {
    display: inline-block;
    margin-left: 30px;
    /* adjust as needed */
    vertical-align: middle;
    border-radius: 12px;
    color: #ff4500;
    text-decoration: none;
    cursor: pointer;
}

.log-in__link:hover {
    color: #ff4500;
    text-decoration: none;
}

.terms__link {
    display: inline-block;
    /* adjust as needed */
    color: #ff4500;
    text-decoration: none;
    cursor: pointer;
}

.terms__link:hover {
    text-decoration: underline;
    color: #ff4500;
}
.vl {
    width: 2px;
    background-color: #ccc;
    height: 600px;
    align-self: center;
}
.sign-up-img{
     max-width: 70%;
    align-self: center;

}
.terms__label {
    line-height: 1.5;
    margin: 10px 0px;
}

.sign-up form label{
color:black;
}
@media screen and (max-width: 768px) {
    .image-container {
            display: none;
            }
            
            .vl {
                display: none;
            }  
     .form-container {
         width: 100%;
         align-self: center;
        }
                         .signUp-logo img {
                             max-width: 300px;
                             align-self: center;
                             margin-left: 10px;
                         }

.terms__label {
    /* display: inline-block; */
    line-height: 1.5;
    margin: 10px 0px;
}
            
        }

