*{
  margin:0;
  padding:0;
  box-sizing: border-box;
  font-family: 'Roboto' , sans-serif;
  /* font-weight: 300; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}
html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-width: 100vw;/* 
    display: flex;
  flex-direction: column; */
   /*    overflow-x: hidden; */
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: var(--color-bg);
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size:1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.75rem;
}
p {
  margin-bottom: 1rem;
}
th {
  text-align: -webkit-match-parent;
  text-align: match-parent;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"] {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color:black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type="checkbox"] {
  display: inline-block;
  width: auto;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  border-color: green;
color: green;
}
input[type="checkbox"]:focus {
  border-color: green;
  outline: 0;
color: green;
box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
button {
  /* padding: 0.375rem 0.75rem; */
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
}
select {
  font-size: 1rem;
  line-height: 1.5;
  color: black;
  background-clip: padding-box;
  border: 1px solid black;
  border-radius: 0.25rem;
}
#root{
  /* background-color: #9E0FEC; */
  background-color: #a22be2;
/*   display: flex;
    flex-direction: column; */
  width: 100%;
  height: 100%;
}
:root {
  --font-family : 'Roboto', sans-serif; 
  /* --color-bg: #9E0FEC; */
  --color-bg: #a22be2;
  --color-footer:#0C0C0C;
  --color-text:pink;
    
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

