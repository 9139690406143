.unsubscribe {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
   
}
.unsubscribe .error_msg,
.unsubscribe .success_unsubscribe {
    width: 320px;
    padding: 15px;
    margin: 10px;
    font-size: 14px;
    background-color: #46f35a;
    color: black;
    border-radius: 5px;
    text-align: justify;
}
.success_unsubscribe {
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    text-align: justify;
}

.success_unsubscribe img {
max-width: 30%;
}  