.read-more-less{
    background-color:#a40aa3;
    text-align: justify;
    /* font-weight: 400; */
    font-size: 18px;
    padding: 2rem;
    border-radius:0.5rem;
    margin: 2rem;
    color: white;
    box-shadow: 0 0 1rem rgba(0,0,0,.5);
  /*   clear: both; */
}
.read-button{
    background-color: #ff4500;
    border: none;
    font-weight: 500;
    color:#000;
    cursor:pointer;
}
.read-more-less h1,h2,h3{
    color: #ff4500;
}