
.home-container{
max-width: 100%;
    margin: 0 ;
    padding: 0;
}
.date-range-selector {
    display: flex;
    /*  align-items: center; */
    justify-content: center;
    max-width: 100%;
    /*    border-radius: 10px;
        overflow: hidden; */
    font-weight: 500;
    font-size: 1.3rem;
}

.button_2 {
    background-color: transparent;
}
.odds-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;   
}
.odds-c-i  {
    display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 100%;
        width: 90%;
}

.home-content {
    max-width: 100%;
 
}
.left-image,
.right-image
 {
    flex: 0 0 30%;
    max-width: 23%; 
height: auto;
flex-direction: column;
    align-self: flex-end;
}
.invisible-right-image{
    display:none;
}
.odds-component{
flex: 0 0 40%;
    /*  max-width: 33.33%; */
    height: auto;
align-self: flex-start;
    width: 100%;
}

@media (max-width: 555px) {
.left-image,
    .right-image {
        display: none;
    }
  .invisible-right-image {
            display: none;

  }
}

