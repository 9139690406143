#p04 table{
 max-width: 100%;
text-align: center;
}
#p04 td {
    text-align: center;
    border: 0.5px solid;
}
#p04 th{
    text-align: center;
    background-color: #ff4500;
   border: 1.5px solid;
}

#p00 ul li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
#p05 p ul {
    list-style:disc;
    color: black;
    list-style-position: inside;
    list-style-color: black;
  
}

#p01  ul li p ul {
    list-style: square;
        color: black;
        list-style-position: inside;
        list-style-color: black;
}