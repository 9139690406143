.pw-reset {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pw-reset form {
/* ff 3.6+ */
    background: -moz-radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 0% 14%, #FF4500 2%, #FF0080 21%, #FF00FF 46%, #A40AA3 74%, #660099 87%);
    padding: 20px;
    border-radius: 20px;

    width: 400px;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
        0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
.pw-reset form h1 {
    color: #660099;
    text-align: center;
}

.pw-reset form input {
    outline: none;
    border: none;
    width: 370px;
    padding: 15px;
    border-radius: 10px;
    background-color: #edf5f3;
    margin: 10px 0;
    font-size: 14px;
}

.pw-reset form button {
    width: 100%;
        height: 50px;
        padding: 10px;
        border: none;
        background-color: #FF4500;
        color: #660099;
        border-radius: 5px;
        font-weight: bold;
        font-size: 21px;
        cursor: pointer;
        margin-top: 15px;
        margin-bottom: 15px;
}
.pw-reset form button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
.pw-reset .error_msg,
.pw-reset .success_msg {
    width: 370px;
    padding: 15px;
    margin: 5px 0;
    font-size: 14px;
    background-color: #f34646;
    color: black;
    border-radius: 5px;
    text-align: center;
}

.pw-reset .success_msg {
    background-color: #5cdd5c;
}

.pw-reset form span {
    background-color: white;
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

.pw-reset form input:invalid[focused="true"]{
    border: 2px solid red;
}

.pw-reset form input:invalid[focused="true"] ~ span {
    display: block;
}